@use '@angular/material' as mat;

@include mat.core();

@import '@angular/material/theming';


/* For use in src/lib/core/theming/_palette.scss */
$md-altagroup-palette: (
  50 : #fafafa,
  100 : #b3d7ec,
  200 : #80bde0,
  300 : #4da3d4,
  400 : #268fca,
  500 : #007bc1,
  600 : #0073bb,
  700 : #0068b3,
  800 : #005eab,
  900 : #004b9e,
  A100 : #c9dfff,
  A200 : #96c0ff,
  A400 : #63a2ff,
  A700 : #4993ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #444444,
    A200 : #3a3a3a,
    A400 : #2c2c2c,
    A700 : #212529,
  )
);





