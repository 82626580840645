@use '@angular/material' as mat;
@use 'sass:map';

@import "theme";
@import 'bootstrap/scss/bootstrap';
@import "bootstrap";
@import 'boxicons';
@import "ngx-bootstrap/datepicker/bs-datepicker.css";

//@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.


$altacloud-v2-web-theme: mat.define-light-theme((
  color: (
    primary: $altacloud-v2-web-primary,
    accent: $altacloud-v2-web-accent,
    warn: $altacloud-v2-web-warn,
  )
));

$color-config: mat.get-color-config($altacloud-v2-web-theme);
$primary-palette: map.get($color-config, 'primary');
$accent-palette: map.get($color-config, 'accent');
$warn-palette: map.get($color-config, 'warn');

@include mat.all-component-themes($altacloud-v2-web-theme);

:root {
  --color-primary-50: #{mat.get-color-from-palette($primary-palette, 50)};
  --color-primary-400: #{mat.get-color-from-palette($primary-palette, 400)};
  --color-primary-500: #{mat.get-color-from-palette($primary-palette, 500)};
  --color-primary-700: #{mat.get-color-from-palette($primary-palette, 700)};
}

* {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

//@font-face {
//     font-family: "Open Sans", sans-serif;
//     src: url("assets/fonts/Calibri.ttf") format('truetype');
//}

html * {
  margin: 0;
  padding: 0;
  //font-family: "Lato", sans-serif;
  font-family: "Ubuntu", sans-serif;
  box-sizing: border-box;
}

.nav, footer, .card, .menu-content, .list-group-item {
  background-color: mat.get-color-from-palette($primary-palette, 50);
}


html,
body {
  height: 100%;
  width: 100%;
  background-color: #ebeff2;
  color: mat.get-color-from-palette($primary-palette, A700-contrast);
}

.content {
  z-index: 10;
  padding: 1.5rem 2rem 1rem 1rem;
  flex: 1 0 auto;
}

.card {
  box-shadow: 0 0.15rem 1.75rem 0 rgb(58 59 69 / 15%) !important;
  //background-color: var(--color-primary-50);
}

.footer {
  flex: 0 0 auto;
}

a:hover {
  cursor: pointer;
}

.text-large {
  font-size: 1.25rem;
}

.page-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 1.25rem;
  color: rgba(0, 0, 0, 0.7);
  font-size: 1.75rem;

  .tree-link {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.7);
    cursor: pointer;

    &:hover {
      color: rgba(33, 43, 87, 0.7);
      transition: 0.3s;
    }
  }

  .sub-nav {
    display: flex;
    align-items: center;

    a {
      text-decoration: none;
      color: rgba(0, 0, 0, 0.7);

      &:hover {
        color: rgba(33, 43, 87, 0.7);
        transition: 0.3s;
      }
    }

    span {
      margin: 0 1rem;
      font-size: 1rem;
      overflow-wrap: break-word;
    }
  }

  &.sm {
    font-size: 1.25rem;
  }
}

.width-100 {
  width: 100% !important;
}

.height-100 {
  height: 100% !important;
}

.flex {
  display: flex !important;
}

.justify-content-center {
  display: flex;
  justify-content: center;
}

.space-between {
  display: flex;
  justify-content: space-between !important;
}

.space-around {
  justify-content: space-around !important;
}

ngx-colors-panel {
  .add {
    line-height: unset !important;
  }
}

//------------------------------------------------------YOUTRACK------------------------------------------------------------

.light_f331 > *, .popup_f21d, .tooltip_fbfb, .popup_f35e, .font_a1f6, {
  z-index: 9999 !important;
}


//-------------------------------------------------------------SCROLL-----------------------------------------------------------


.scroll {
  -ms-overflow-style: none;
  scroll-behavior: smooth;
  overscroll-behavior: contain;
}

.scrollbox-horizontal {
  background-image: /* Shadows */
    linear-gradient(to right, var(--color-primary-50), var(--color-primary-50)),
    linear-gradient(to left, var(--color-primary-50), var(--color-primary-50)),
      /* Shadow covers */
    linear-gradient(to right, rgba(0, 0, 0, .25), rgba(255, 255, 255, 0)),
    linear-gradient(to left, rgba(0, 0, 0, .25), rgba(255, 255, 255, 0));

  background-position: left center, right center, left center, right center;
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: 20px 100%, 20px 100%, 10px 100%, 10px 100%;

  /* Opera doesn't support this in the shorthand */
  background-attachment: local, local, scroll, scroll;
}

.scrollbox-vertical {
  background-image: linear-gradient(to top, var(--color-primary-50), var(--color-primary-50)),
  linear-gradient(to top, var(--color-primary-50), var(--color-primary-50)),
  linear-gradient(to top, rgba(0, 0, 0, 0.25), rgba(255, 255, 255, 0)),
  linear-gradient(to bottom, rgba(0, 0, 0, 0.25), rgba(255, 255, 255, 0));
  background-position: bottom center, top center, bottom center, top center;
  background-color: white;
  background-repeat: no-repeat;
  background-size: 100% 20px, 100% 20px, 100% 10px, 100% 10px;
  background-attachment: local, local, scroll, scroll;
}

//-------------------------------------------------------------SNACKBAR-----------------------------------------------------------

//.danger.mat-snack-bar-container {
//  max-width: max-content !important;
//  color: white;
//  //--mdc-snackbar-container-color: #dc3545;
//  border-color: #f5c6cb;
//}
//
//.success {
//  color: white;
//  --mdc-snackbar-container-color: #198754;
////
//  .mat-simple-snackbar-action {
//    color: white;
//  }
//}


.success.mat-mdc-snack-bar-container {
  .mdc-snackbar__surface {
    background: #6CA759;

    .mat-mdc-button {
      color: white !important;

      &::before {
        content: "\2716";
      }
    }
  }

  .mat-mdc-snack-bar-label.mdc-snackbar__label {
    color: white;
    font-weight: 500;
    white-space: pre-wrap
  }
}

.danger.mat-mdc-snack-bar-container {
  .mdc-snackbar__surface {
    background: #dc3545;

    .mat-mdc-button {
      color: white !important;

      &::before {
        content: "\2716";
      }
    }
  }

  .mat-mdc-snack-bar-label.mdc-snackbar__label {
    color: white;
    font-weight: 500;
    white-space: pre-wrap
  }
}

//-------------------------------------------------------------SNACKBAR-----------------------------------------------------------

//-------------------------------------------------------------SORTABLE-----------------------------------------------------------

.sortable-wrapper {
  border: 1px solid rgba(117, 117, 117, 0.7);
  border-radius: 0.35rem;
  //margin: 1rem 0;
  padding: 0.6rem 0.1rem;
  height: 20rem;
  width: 100%;
  overflow-y: auto;

  &.cdk-drop-list-disabled {
    background: #ccc;
    cursor: not-allowed;
    user-select: none;
  }
}

button.sortable-item {
  border-radius: 0.2rem;
  border: 1px solid rgba(117, 117, 117, 0.4);
  padding: 0.5rem 1rem;
  text-align: center;
  transition: ease-in-out 200ms;

  &:hover {
    background-color: rgba(157, 157, 157, 0.3);
    border: 1px solid rgba(117, 117, 117, 0.4);
  }

  &:active {
    border: 1px solid rgba(117, 117, 117, 0.4) !important;
    background-color: rgba(134, 134, 134, 0.4) !important;
  }

  &.active {
    border: 1px solid rgba(117, 117, 117, 0.4) !important;
    background-color: rgba(121, 121, 121, 0.4) !important;
  }
}

.sortable-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sortable-item {
  border-radius: 0.2rem;
  border: 1px solid rgba(117, 117, 117, 0.4);
  padding: 0.5rem 1rem;
  text-align: center;

  &:hover {
    background-color: rgba(157, 157, 157, 0.3);
  }
}

.sortable-item-active {
  background-color: rgba(134, 134, 134, 0.4);
}

//-------------------------------------------------------------BUTTONS------------------------------------------------------------

.nav-link {
  color: #006093;

  &:hover {
    color: #006f9d;
  }
}

.btn {
  border-radius: 10px;
  border: 1px solid #fff;

  &.none-border {
    border: none;
  }

  &.m {
    margin: 1rem 0;
  }
}

.btn-log {
  color: #dfd6d6;
  background-color: #007ac1;
  border-radius: 30px;
  width: 75%;

  &:disabled {
    background-color: darkgray;
    color: #FFFFFF;
  }

  &:hover {
    color: white;
    background-color: #008cc7;
    border-radius: 30px;
    width: 75%;
  }
}

.btn-change {
  color: #008cc7;
  background: transparent;
  border: none;
  width: 40%;
  border-radius: 30px;

  &:hover {
    color: #005f96;
    background: #c5d0e6;
  }
}

.btn-outline-secondary {
  background-color: darkgray;
  color: #FFFFFF;
  border-radius: 10px;
}

.bs-datepicker-predefined-btns button {
  min-height: 2.3rem;
  text-align: center;
}

.btn-primary, .bs-datepicker-predefined-btns button {
  color: #dfd6d6;
  background-color: #007ac1;
  min-width: 30%;

  &:disabled {
    background-color: darkgray;
    color: #FFFFFF;
    border: 1px solid #fff;
  }

  &:hover {
    color: white;
    background-color: #008cc7;
    border: 1px solid #008cc7;
  }
}

.btn-link {
  color: #094E7D;

  &:hover {
    color: #042e4b;
  }
}

.btn-link-danger {
  color: #bd2323;

  &:hover {
    color: #650f0f;
  }
}

.btn-settings {
  color: #dfd6d6;
  background-color: #094E7D;
  min-width: 30%;

  &:hover {
    color: white;
    background-color: #042e4b;
  }
}

.form-check-input {
  transition: 0.2s ease-in-out;

  &:checked {
    cursor: pointer;
    background-color: #094E7D;
    border-color: #094E7D;
  }
}

//--------------------------------------------LIST GROUP-------------------------------

.list-group-item {
  display: flex;
  align-items: center;
  min-height: 3.5rem;
  color: mat.get-color-from-palette($primary-palette, A700-contrast);
  transition: color .1s ease-in-out, background-color .1s ease-in-out, border-color .1s ease-in-out;

  &:hover {
    background-color: rgba(0, 140, 199, 0.7);
    color: #FFFFFF;

    .active {
      background-color: #008cc7;
      color: #FFFFFF;
    }
  }

  .row {
    width: 100%;
  }

  &:nth-child(0) {
    border-top-right-radius: inherit;
    border-top-left-radius: inherit;
  }

  &.new {
    background-color: transparent;
    border: 0;
    text-align: center;
    font-size: 2rem;
    opacity: 0.3;

    &:hover {
      color: black;
      opacity: 0.7;
      transition: 300ms ease-in-out;

      i {
        background-color: rgba(0, 122, 193, 0.3);
        border-radius: 50%;
      }
    }
  }
}

.app-color-picker {
  .preview {
    border-radius: 10px !important;
    color: transparent !important;
    background-color: transparent !important;

    .circle {
      display: block;
      width: 11rem !important;
      height: 2rem !important;
      border-radius: 10px !important;
    }
  }
}

//--------------------------------------DROPDOWN-------------------------------------------


.dropdown-menu {
  box-shadow: 0 0.15rem 1.75rem 0 rgb(58 59 69 / 15%) !important;
  scroll-behavior: smooth;
  overscroll-behavior: contain;
}

// todo

// .mdc-list-item__primary-text {
//   width: 100%;
//   text-align: center;
// }

.mat-mdc-menu-panel {
  max-width: none !important;
}

.dropdown-item {
  font-size: 1rem;
  border: 0;
  padding: 1rem;

  &:active {
    background-color: #f3f3f3;
    color: black;
    transition: ease;
    transition-duration: 0.2s;
  }
}

.dropdown-header {
  background-color: #007ac1;
  border: 1px solid #007ac1;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  color: #fff;
  text-transform: uppercase !important;
  font-weight: bolder;
  white-space: break-spaces;
}

.theme-dark-blue .bs-datepicker-head {
  background-color: #007ac1 !important;
}


//------------------------------------------ANIMATIONS---------------------------------------------------

.slide-down-animated-component {
  overflow: hidden;
}

.animated--grow-in {
  -webkit-animation-name: growIn;
  animation-name: growIn;
  -webkit-animation-duration: 200ms;
  animation-duration: 200ms;
  -webkit-animation-timing-function: transform cubic-bezier(0.18, 1.25, 0.4, 1), opacity cubic-bezier(0, 1, 0.4, 1);
  animation-timing-function: transform cubic-bezier(0.18, 1.25, 0.4, 1), opacity cubic-bezier(0, 1, 0.4, 1);
}

.animated--slide-down {
  -webkit-animation-name: slideDown;
  animation-name: slideDown;
  -webkit-animation-duration: 400ms;
  animation-duration: 400ms;
  -webkit-animation-timing-function: transform cubic-bezier(0.18, 1.25, 0.4, 1), opacity cubic-bezier(0, 1, 0.4, 1);
  animation-timing-function: transform cubic-bezier(0.18, 1.25, 0.4, 1), opacity cubic-bezier(0, 1, 0.4, 1);
}

.animated--fade-in {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
  -webkit-animation-timing-function: transform cubic-bezier(0.18, 1.25, 0.4, 1), opacity cubic-bezier(0, 1, 0.4, 1);
  animation-timing-function: transform cubic-bezier(0.18, 1.25, 0.4, 1), opacity cubic-bezier(0, 1, 0.4, 1);
}

.animated-flash {
  animation: flashing 1500ms ease-in-out infinite;
}

@keyframes flashing {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes growIn {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  55% {
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideDown {
  0% {
    transform: translate(0, -40px);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@-webkit-keyframes slideDown {
  0% {
    transform: translate(0, -40px);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@media screen and (max-width: 1199px) {
  .content {
    padding: 1.5rem 1.5rem;
    flex: 1 0 auto;
  }

  .wrapper {
    margin-left: 0;
  }
}

@media screen and (max-width: 799px) {
  .content {
    //padding: 1rem 1rem;
    flex: 1 0 auto;
    padding: 1.5rem 0;
  }

  .wrapper {
    margin-left: 0;
  }

  .page-title {
    font-size: 1.5rem;

    .sub-nav {
    }
  }

  .btn-primary {
    width: 100%;
  }

}

@media screen and (max-width: 399px) {
  .page-title {
    justify-content: center;

    &.sm {
      font-size: 1rem;
    }

    .sub-nav {
      flex-direction: column;
    }
  }

}


html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  position: relative;
}

@mixin custom-scroll {
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: unset;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, .15);
    border-radius: 5px;
  }
}


//-------------------------------------------------------------TOOLTIPS-----------------------------------------------------------


.tooltip {
  position: absolute;
  z-index: 10;

  background: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  color: white;

  transition: .3s all;

  span {
    background: unset !important;
  }
}
